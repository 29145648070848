import { t } from 'bv-i18n';
import {
  apiFetchTimeOutPeriods,
  apiTimeOutUser,
  apiFetchUserIsOnTimeOut,
} from './api';

export const FETCH_USER_IS_ON_TIME_OUT_INIT = 'TIME_OUT.FETCH_USER_IS_ON_TIME_OUT.INIT';
export const FETCH_USER_IS_ON_TIME_OUT_COMPLETE = 'TIME_OUT.FETCH_USER_IS_ON_TIME_OUT.COMPLETE';
export const FETCH_USER_IS_ON_TIME_OUT_ERROR = 'TIME_OUT.FETCH_USER_IS_ON_TIME_OUT.ERROR';
export const FETCH_TIME_OUT_PERIODS_INIT = 'TIME_OUT.FETCH_TIME_OUT_PERIODS.INIT';
export const FETCH_TIME_OUT_PERIODS_COMPLETE = 'TIME_OUT.FETCH_TIME_OUT_PERIODS.COMPLETE';
export const FETCH_TIME_OUT_PERIODS_ERROR = 'TIME_OUT.FETCH_TIME_OUT_PERIODS.ERROR';
export const TIME_OUT_USER_INIT = 'TIME_OUT.TIME_OUT_USER.INIT';
export const TIME_OUT_USER_SUCCESS = 'TIME_OUT.TIME_OUT_USER.SUCCESS';
export const TIME_OUT_EXPIRED = 'TIME_OUT.TIME_OUT_EXPIRED';
export const TIME_OUT_USER_FAILURE = 'TIME_OUT.TIME_OUT_USER.FAILURE';
export const CLOSE_SUBMIT_RESULT_MODAL = 'TIME_OUT.CLOSE_SUBMIT_RESULT_MODAL';
export const CLOSE_SUCCEEDED_MODAL = 'TIME_OUT.CLOSE_SUCCEEDED_MODAL';

export const fetchTimeOutPeriodsInit = () => ({
  type: FETCH_TIME_OUT_PERIODS_INIT,
});

export const fetchTimeOutPeriodsComplete = (periods) => ({
  type: FETCH_TIME_OUT_PERIODS_COMPLETE,
  periods,
});

export const fetchTimeOutPeriodsError = () => ({
  type: FETCH_TIME_OUT_PERIODS_ERROR,
});

export const fetchUserIsOnTimeOutInit = () => ({
  type: FETCH_USER_IS_ON_TIME_OUT_INIT,
});

export const fetchUserIsOnTimeOutComplete = ({ status, periodId }) => ({
  type: FETCH_USER_IS_ON_TIME_OUT_COMPLETE,
  isActive: status === 'TIME_OUT' || status === 'PENDING_CONFIRMATION',
  periodId,
});

export const fetchUserIsOnTimeOutError = () => ({
  type: FETCH_USER_IS_ON_TIME_OUT_ERROR,
});

export const timeOutUserInit = () => ({
  type: TIME_OUT_USER_INIT,
});

export const timeOutUserSuccess = ({ period }) => ({
  type: TIME_OUT_USER_SUCCESS,
  period,
});

export const clearTimeOut = () => ({
  type: TIME_OUT_EXPIRED,
});

export const timeOutUserFailure = (error) => ({
  type: TIME_OUT_USER_FAILURE,
  error,
});

export const closeSubmitResultModal = () => ({
  type: CLOSE_SUBMIT_RESULT_MODAL,
});

export const closeSucceededModal = () => ({
  type: CLOSE_SUCCEEDED_MODAL,
});

export const fetchTimeOutPeriods = () => (dispatch) => {
  dispatch(fetchTimeOutPeriodsInit());

  return apiFetchTimeOutPeriods().then((data) => {
    if (data.length) {
      dispatch(fetchTimeOutPeriodsComplete(data));
    } else {
      dispatch(fetchTimeOutPeriodsError());
    }
  });
};

export const fetchUserIsOnTimeOut = () => (dispatch) => {
  dispatch(fetchUserIsOnTimeOutInit());
  return apiFetchUserIsOnTimeOut().then((data) => {
    if (data) {
      dispatch(fetchUserIsOnTimeOutComplete(data));
    } else {
      dispatch(fetchUserIsOnTimeOutError());
    }
  });
};

export const timeOutUser = ({ period, password }) => (dispatch) => {
  dispatch(timeOutUserInit());

  return apiTimeOutUser({ period, password }).then((timeOutResponse) => {
    if (timeOutResponse.success) {
      dispatch(timeOutUserSuccess({ period }));
    } else if (timeOutResponse.wrongPassword) {
      dispatch(timeOutUserFailure(t('time_out.invalid_password')));
    } else {
      dispatch(timeOutUserFailure(t('time_out.general_error')));
    }
  });
};

const initialState = {
  timeOutPeriodsAreLoading: false,
  timeOutPeriods: [],
  timeOutPeriodsError: false,
  timeOutStatusLoaded: false,
  timeOutPeriodsLoaded: false,
  timeOutStatusError: false,
  userIsOnTimeOut: false,
  activePeriodId: 0,
  userTimeOutSucceeded: false,
  userTimeOutFailed: false,
  error: null,
  defaultGamblingSupportProviderLoaded: false,
  defaultGamblingSupportProvider: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_TIME_OUT_PERIODS_INIT: {
      return {
        ...state,
        timeOutPeriodsAreLoading: true,
      };
    }
    case FETCH_TIME_OUT_PERIODS_COMPLETE: {
      return {
        ...state,
        timeOutPeriodsAreLoading: false,
        timeOutPeriodsLoaded: true,
        timeOutPeriods: action.periods,
      };
    }
    case FETCH_TIME_OUT_PERIODS_ERROR: {
      return {
        ...state,
        timeOutPeriodsAreLoading: false,
        timeOutPeriodsLoaded: true,
        timeOutPeriodsError: true,
      };
    }
    case FETCH_USER_IS_ON_TIME_OUT_INIT: {
      return {
        ...state,
      };
    }
    case FETCH_USER_IS_ON_TIME_OUT_COMPLETE: {
      return {
        ...state,
        timeOutStatusLoaded: true,
        userIsOnTimeOut: action.isActive,
        activePeriodId: action.periodId,
      };
    }
    case FETCH_USER_IS_ON_TIME_OUT_ERROR: {
      return {
        ...state,
        timeOutStatusLoaded: true,
        timeOutStatusError: true,
      };
    }
    case TIME_OUT_USER_INIT: {
      return {
        ...state,
      };
    }
    case TIME_OUT_USER_SUCCESS: {
      return {
        ...state,
        userIsOnTimeOut: true,
        activePeriodId: action.period,
        timeOutStatusLoaded: true,
        userTimeOutSucceeded: true,
      };
    }
    case TIME_OUT_USER_FAILURE: {
      return {
        ...state,
        userIsOnTimeOut: false,
        userTimeOutFailed: true,
        error: action.error,
      };
    }
    case TIME_OUT_EXPIRED: {
      return {
        ...state,
        userIsOnTimeOut: false,
        activePeriodId: null,
      };
    }
    case CLOSE_SUCCEEDED_MODAL: {
      return {
        ...state,
        userTimeOutSucceeded: false,
      };
    }
    case CLOSE_SUBMIT_RESULT_MODAL: {
      return {
        ...state,
        userTimeOutSucceeded: false,
        userTimeOutFailed: false,
        error: null,
      };
    }
    default:
      return state;
  }
};
